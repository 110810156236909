<template>
  <div>
    <service-treeview
      :route-type="'REPORT-HUB'"
      :is-policing="true"
      :is-creating-profiling="true"
    />
  </div>
</template>

<script>
export default {
  components: {
    ServiceTreeview: () => import('@/components/services/ServiceTreeview')
  },
  props: {
    service: {
      type: Object,
      default: null
    }
  }
};
</script>
